import React from "react";
import Logo from "../pics/Beacon Institute Swansea.png";
import "../components/Home.css";

const Home = () => {
  return (
    <div id="home" className="home-component">
      <img className="bigLogo" src={Logo} alt="error"></img>
      <div className="btn-container">
        <h1 className="home-title">The Beacon Hub</h1>
        <a className="btn" href="#about-us">Find Out More</a>
      </div>
      
    </div>
  );
};

export default Home;