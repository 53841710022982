import React from "react";
import "./App.css";
import Home from "./components/Home";
import SalahTimes from "./components/SalahTimes";
import AboutUs from "./components/AboutUs";
import Donate from "./components/Donate";
import Navbar from "./components/Navbar";
import ContactUs from "./components/ContactUs";

const App = () => (
  <div>
    <Navbar />
    <Home />
    <SalahTimes />
    <AboutUs />
    <Donate />
    <ContactUs />
  </div>
);

export default App;
